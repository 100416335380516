import React, { Fragment } from "react"
import FilterButtonStyles from "./filterButton.module.css"
import CloseIcon from "../../images/close.svg"

const HideFilterButton = () => (
  <Fragment>
    <div className="col-sm-hidden">HIDE FILTERS</div>
    <div className="hidden">
      <img
        className={FilterButtonStyles.close_icon}
        src={CloseIcon}
        alt="Close"
      />
    </div>
  </Fragment>
)

const ShowFilterButton = () => (
  <Fragment>
    <div className={FilterButtonStyles.show_button}>SHOW FILTERS</div>
  </Fragment>
)

const FilterButton = ({ onClick, isFilterOpen, onlyLine = false }) => (
  <Fragment>
    <div
      className={`${FilterButtonStyles.separator} ${
        isFilterOpen ? FilterButtonStyles.active : ""
      }`}
    >
      {!onlyLine && (
        <div className={FilterButtonStyles.text} onClick={onClick}>
          {isFilterOpen ? <HideFilterButton /> : <ShowFilterButton />}
        </div>
      )}
    </div>
  </Fragment>
)

export default FilterButton
