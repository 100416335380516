import React from "react"
import { Link } from "gatsby"
import WorkWrapperStyles from "./workwrapper.module.css"

function WorkWrapper(props) {
  const frontmatter = props
  const { title, path, cover } = props

  return (
    <div className="col-4 col-sm">
      <Link to={path} className={WorkWrapperStyles.link}>
        <img alt={title} src={cover} />
        <span className={WorkWrapperStyles.title}>{title}</span>
        <div className={WorkWrapperStyles.line}></div>
      </Link>
    </div>
  )
}
export default WorkWrapper
