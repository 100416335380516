import React, { useState, useEffect, useRef } from "react"
import { graphql, StaticQuery } from "gatsby"
import AnimateHeight from "react-animate-height"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WorkWrapper from "../components/WorkWrapper"
import Footer from "../components/Footer"
import WorksStyles from "./works.module.css"
import FilterButton from "../components/FilterButton"

const Works = props => {
  const { data } = props

  const works = data.allMarkdownRemark.edges
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [filteredWork, setFilteredWork] = useState([])

  const [selectedArea, setSelectedArea] = useState("")
  const [activeAreas, setActiveAreas] = useState([])

  const [selectedService, setSelectedService] = useState("")
  const [activeServices, setActiveServices] = useState([])

  const filterRef = useRef(null)

  const resetFilter = () => {
    setActiveAreas(allAreas)
    setActiveServices(allServices)

    setFilteredWork(works)

    setSelectedArea("")
    setSelectedService("")
    filterRef.current.focus()
  }

  const allAreas = [
    "Business Design",
    "Experience Design",
    "Interactive",
    "moodley",
  ]
  const allServices = [
    "Web Development",
    "Mobile App",
    "AR/VR Applications",
    "Growth Marketing",
    "Event Concept Design",

    "Event Experience Design",
    "Event Management",
    "Business Strategy",
    "Business Model Design",
    "Business Transformation",

    "Brand Strategy and Design",
    "Industrial Design",
    "Customer Experience Design",
    "Service Design",
  ]

  useEffect(() => {
    setFilteredWork(works)
    setActiveAreas(allAreas)
    setActiveServices(allServices)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //filter functions
  const simulateFilter = ({ service, area }) => {
    filterRef.current.focus()
    //filter selection
    let selected_area = selectedArea
    let selected_service = selectedService

    //if area is selected and service not selected, show only the area's services
    let onlyAreaSelected =
      (area || selectedArea) && !(service || selectedService)

    //if service selected and area not selected, show only the service's areas
    let onlyServiceSelected =
      (service || selectedService) && !(area || selectedArea)

    //if servie and area selected
    let serviceAndAreaSelected =
      (area || selectedArea) && (service || selectedService)

    if (area) {
      if (selectedService && selectedArea) {
        onlyAreaSelected = true
        serviceAndAreaSelected = false
        setSelectedService([])
      }
      setSelectedArea(area)
      selected_area = area
    }

    if (service) {
      setSelectedService(service)
      selected_service = service
    }

    //if only area selected show only the area's works & activate services
    if (onlyAreaSelected) {
      const activatedServices = new Set()
      setFilteredWork(
        works
          .map(work => {
            if (work.node.frontmatter.areas.includes(area)) {
              work.node.frontmatter.services.map(areaService =>
                activatedServices.add(areaService)
              )
              return work
            }
            return null
          })
          .filter(work => work !== null)
      )
      setActiveAreas([area])
      setActiveServices([...activatedServices])
    }

    //if only service selected show only the service's works & activate services areas
    if (onlyServiceSelected) {
      const activatedAreas = new Set()
      const activatedServices = new Set()

      setFilteredWork(
        works
          .map(work => {
            if (work.node.frontmatter.services.includes(service)) {
              work.node.frontmatter.areas.map(serviceArea =>
                activatedAreas.add(serviceArea)
              )

              work.node.frontmatter.services.map(siblignService =>
                activatedServices.add(siblignService)
              )
              return work
            }
            return null
          })
          .filter(work => work !== null)
      )
      setActiveAreas([...activatedAreas])
      setActiveServices([selected_service])
    }

    //if area and service selected show the area's service's works
    if (serviceAndAreaSelected) {
      const activatedServices = new Set()
      setFilteredWork(
        works
          .map(work => {
            const hasWorkArea = work.node.frontmatter.areas.includes(
              selected_area
            )
            const hasWorkService = work.node.frontmatter.services.includes(
              selected_service
            )

            if (hasWorkArea) {
              work.node.frontmatter.services.map(areaService =>
                activatedServices.add(areaService)
              )
            }

            if (hasWorkArea && hasWorkService) {
              return work
            }

            return null
          })
          .filter(work => work !== null)
      )

      setActiveAreas([selected_area])
      //setActiveServices([selected_service])
      setActiveServices([...activatedServices])
    }

    return
    //end of the filter
  }

  useEffect(() => {
    return () => {
      document.body.classList.remove("drawer-open")
    }
  }, [])

  const handleDrawerToggle = () => {
    document.body.classList.remove("drawer-open")

    if (!isFilterOpen) {
      document.body.classList.add("drawer-open")
      filterRef.current.focus()
    }

    setIsFilterOpen(!isFilterOpen)
  }
  return (
    <Layout>
      <SEO title="Works" />
      <h1 className={"hidden"}>Works</h1>

      {/* FILTER START */}
      <div
        className={`col-sm mt-90 ${WorksStyles.filter_wrapper} ${isFilterOpen &&
          WorksStyles.active_filter}`}
      >
        <FilterButton
          isFilterOpen={isFilterOpen}
          onClick={() => handleDrawerToggle()}
        />
        <AnimateHeight height={!isFilterOpen ? 0 : "auto"}>
          <div name="FILTERS" className={WorksStyles.filter} ref={filterRef}>
            <div className="row">
              <div
                className={`col-3 col-lg-2 col-md-2 col-sm ${WorksStyles.areas_block}`}
              >
                <div className="row">
                  <div className="col-12">
                    <span className={WorksStyles.category_title}>
                      <b>AREA</b>
                    </span>
                    <br />
                    <br />
                  </div>
                  <div className="col-12">
                    <div className="row">
                      {allAreas.map(area => (
                        <div
                          role="button"
                          tabIndex={0}
                          className={`col-12 ${WorksStyles.area_text} ${
                            selectedArea === area
                              ? WorksStyles.selected_area
                              : ""
                          } ${WorksStyles.filter_text} ${
                            !activeAreas.includes(area)
                              ? WorksStyles.disabled_area_text
                              : ""
                          }`}
                          onKeyDown={e => {
                            if (e.keyCode === 13) simulateFilter({ area })
                          }}
                          onClick={() => simulateFilter({ area })}
                          key={area}
                        >
                          {area}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`col-6 col-lg-8 col-md-10 col-sm ${WorksStyles.services_block}`}
              >
                <div className="row">
                  <div className="col-12">
                    <span className={WorksStyles.category_title}>
                      <b>SERVICE</b>
                    </span>
                    <br />
                    <br />
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-4 col-sm">
                        <div className="row">
                          {allServices.map(
                            (service, i) =>
                              i < 5 && (
                                <div
                                  className={`col-12 ${
                                    WorksStyles.service_text
                                  } 
                                ${
                                  selectedService === service
                                    ? WorksStyles.selected_service
                                    : ""
                                }
                                ${WorksStyles.filter_text} ${
                                    activeServices &&
                                    !activeServices.includes(service)
                                      ? WorksStyles.disabled_service_text
                                      : ""
                                  }`}
                                  role="button"
                                  tabIndex={0}
                                  key={service}
                                  onKeyDown={e => {
                                    if (e.keyCode === 13)
                                      simulateFilter({ service })
                                  }}
                                  onClick={() => simulateFilter({ service })}
                                >
                                  <div>{service}</div>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                      <div className="col-4 col-sm">
                        <div className="row">
                          {allServices.map(
                            (service, i) =>
                              5 <= i &&
                              i < 10 && (
                                <div
                                  className={`col-12 ${
                                    WorksStyles.service_text
                                  }  ${
                                    selectedService === service
                                      ? WorksStyles.selected_service
                                      : ""
                                  } ${WorksStyles.filter_text} ${
                                    activeServices &&
                                    !activeServices.includes(service)
                                      ? WorksStyles.disabled_service_text
                                      : ""
                                  }`}
                                  role="button"
                                  tabIndex={0}
                                  key={service}
                                  onKeyDown={e => {
                                    if (e.keyCode === 13)
                                      simulateFilter({ service })
                                  }}
                                  onClick={() => simulateFilter({ service })}
                                >
                                  <div>{service}</div>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                      <div className="col-4 col-sm">
                        <div className="row">
                          {allServices.map(
                            (service, i) =>
                              10 <= i && (
                                <div
                                  className={`col-12 ${
                                    WorksStyles.service_text
                                  } ${
                                    selectedService === service
                                      ? WorksStyles.selected_service
                                      : ""
                                  } ${WorksStyles.filter_text} ${
                                    activeServices &&
                                    !activeServices.includes(service)
                                      ? WorksStyles.disabled_service_text
                                      : ""
                                  }`}
                                  role="button"
                                  tabIndex={0}
                                  key={service}
                                  onKeyDown={e => {
                                    if (e.keyCode === 13)
                                      simulateFilter({ service })
                                  }}
                                  onClick={() => simulateFilter({ service })}
                                >
                                  <div>{service}</div>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`col-3 col-lg-2 col-md col-sm ${WorksStyles.centered}`}
              >
                <div
                  className={WorksStyles.clear_filter_button}
                  role="button"
                  tabIndex={0}
                  onKeyDown={e => {
                    if (e.keyCode === 13) resetFilter()
                  }}
                  onClick={() => {
                    resetFilter()
                  }}
                >
                  <span>
                    <b>CLEAR FILTERS</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-40">
            <FilterButton onlyLine={true} />
          </div>
        </AnimateHeight>
      </div>
      {/* FILTER END */}

      <div className="row">
        {filteredWork.map(({ node }, i) => {
          const { frontmatter } = node

          return <WorkWrapper key={i} {...frontmatter} />
        })}
      </div>
      <Footer />
    </Layout>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          sort: { fields: [frontmatter___order], order: DESC }
        ) {
          edges {
            node {
              id
              frontmatter {
                id
                title
                path
                cover
                order
                areas
                services
              }
            }
          }
        }
      }
    `}
    render={data => <Works data={data} {...props} />}
  />
)
